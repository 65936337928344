.title {
  font-size: 1.9375rem;

  @screen sm {
    font-size: 2.0625rem;
  }
}

.section-header {
  font-size: 1.0625rem;

  @screen sm {
    font-size: 1.375rem;
  }
}

.footer-links {
  font-size: 0.6875rem;

  > a {
    &:not(:last-child) {
      &::after {
        content: '|';
        margin-left: 0.5em;
        margin-right: 0.5em;
      }
    }
  }
}

.list {
  @apply list-disc pl-4;

  ul {
    @apply list-none pl-4;

    li {
      &::before {
        @apply absolute;

        content: '-';
        margin-left: -1em;
      }
    }
  }
}
