[data-definition] {
  @apply z-40;
}

.definition-wrapper {
  @apply bg-blue text-white opacity-0;

  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.25);
  z-index: -9999;

  &[data-show] {
    @apply opacity-100 z-50;
  }

  @screen sm {
    @apply bg-white text-gray;

    border-radius: 8px;
    width: 19.25rem;

    &::after {
      @apply absolute inset-0 bg-white;
  
      content: " ";
      border-radius: 8px;
      z-index: 0;
    }
  }

  @screen mobile {
    position: fixed !important;
    left: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    top: auto !important;
    transform: none !important;
  }
}

.definition-arrow,
.definition-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;
}

.definition-arrow::before {
  @apply bg-white;

  content: " ";
  box-shadow: -4px 4px 13px 0 rgba(0, 0, 0, 0.125);
  transform: rotate(45deg);
}

.definition-arrow {
  [data-popper-placement^='top'] & {
    bottom: -4px;
  }
  
  [data-popper-placement^='bottom'] & {
    top: -4px;
  }
  
  [data-popper-placement^='left'] & {
    right: -4px;
  }
  
  [data-popper-placement^='right'] & {
    left: -4px;
  }
}

.definition-term,
.definition-definition {
  @apply block;
}

.definition-close {
  padding-right: 1.25em;

  &::after {
    content: "\00D7";
    font-size: 2em;
    position: absolute;
    margin-left: .125em;
    margin-top: -.25em;
  }
}