@charset "UTF-8";
/*! purgecss start ignore */
@tailwind base;
@tailwind components;
/*! purgecss end ignore */
@tailwind utilities;
/*! purgecss start ignore */
/**
 * @bevacqua/rome - Customizable date (and time) picker. Opt-in UI, no jQuery!
 * @version v3.0.3
 * @link https://github.com/bevacqua/rome
 * @license MIT
 */
.rd-container {
  display: none;
  border: 1px solid #333;
  background-color: #fff;
  padding: 10px;
  text-align: center;
}

.rd-container-attachment {
  position: absolute;
}

.rd-month {
  display: inline-block;
  margin-right: 25px;
}

.rd-month:last-child {
  margin-right: 0;
}

.rd-back,
.rd-next {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
}

.rd-back[disabled],
.rd-next[disabled] {
  cursor: default;
}

.rd-back {
  float: left;
}

.rd-next {
  float: right;
}

.rd-back:before {
  display: block;
  content: "←";
}

.rd-next:before {
  display: block;
  content: "→";
}

.rd-day-body {
  cursor: pointer;
  text-align: center;
}

.rd-day-selected,
.rd-time-selected,
.rd-time-option:hover {
  cursor: pointer;
  background-color: #333;
  color: #fff;
}

.rd-day-prev-month,
.rd-day-next-month {
  color: #999;
}

.rd-day-disabled {
  cursor: default;
  color: #fcc;
}

.rd-time {
  position: relative;
  display: inline-block;
  margin-top: 5px;
  min-width: 80px;
}

.rd-time-list {
  display: none;
  position: absolute;
  overflow-y: scroll;
  max-height: 160px;
  left: 0;
  right: 0;
  background-color: #fff;
  color: #333;
}

.rd-time-selected {
  padding: 5px;
}

.rd-time-option {
  padding: 5px;
}

.rd-day-concealed {
  visibility: hidden;
}

.button {
  @apply inline-block text-blue border border-blue rounded-full font-sans font-bold p-2;
  font-size: 0.8125rem;
}
.button.button-prev {
  @apply pr-3;
}
.button.button-next {
  @apply pl-3;
}
.button:hover {
  @apply text-white bg-blue;
}
@screen sm {
  .button {
    @apply px-5;
  }
  .button.button-prev {
    @apply pl-3;
  }
  .button.button-next {
    @apply pr-3;
  }
}
.button[disabled], .button[disabled]:hover {
  @apply border-light-gray bg-light-gray text-gray cursor-wait;
}

.button-prev::before,
.button-next::after {
  @apply inline-block align-middle;
  content: url("../images/caret-blue.svg");
}

.button-prev::after,
.button-next::before {
  @apply absolute opacity-0 pointer-events-none;
  content: url("../images/caret-white.svg");
}

.button-prev:hover::before,
.button-next:hover::after {
  content: url("../images/caret-white.svg");
}

.button-prev::before {
  @apply mr-2;
  transform: scaleX(-1);
}
@screen sm {
  .button-prev::before {
    @apply mr-3;
  }
}

.button-next::after {
  @apply ml-2;
}
@screen sm {
  .button-next::after {
    @apply ml-3;
  }
}

.button-blue {
  @apply bg-blue text-white;
}
.button-blue:hover {
  @apply bg-white text-blue;
}
.button-blue[disabled], .button-blue[disabled]:hover {
  @apply border-light-gray bg-light-gray text-gray cursor-wait;
}
.button-blue.button-prev::before, .button-blue.button-next::after {
  content: url("../images/caret-white.svg");
}
.button-blue.button-prev:hover::before, .button-blue.button-next:hover::after {
  content: url("../images/caret-blue.svg");
}

.option-card-list {
  @apply flex flex-wrap -mx-2;
}

.option-card-inner {
  @apply relative block w-full h-0;
  padding-bottom: 142.45%;
}
.option-card-custom .option-card-inner {
  padding-bottom: 71.225%;
}
@screen sm {
  .option-card-inner {
    padding-bottom: 200%;
  }
  .option-card-custom .option-card-inner {
    padding-bottom: 200%;
  }
  .option-card-inner::before {
    @apply block absolute top-0 left-0 w-full h-0 bg-white rounded-lg;
    content: " ";
    padding-bottom: 116.4%;
    transition: 0.3s ease;
  }
  .collapsible-item.is-expanded .option-card-inner::before {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  }
  .option-card.active .option-card-inner::before {
    box-shadow: none;
  }
}

.option-card-content {
  @apply absolute inset-y-0 left-0 w-full flex flex-col text-center p-2 bg-white rounded-lg;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}
.option-card:nth-child(2n) .option-card-content {
  @apply right-0 left-auto;
}
.option-card.active .option-card-content {
  @apply z-40;
  box-shadow: none;
  width: calc(200% + theme("spacing.4"));
}
.option-card-custom.active .option-card-content {
  width: 100%;
}
@screen sm {
  .option-card-content {
    @apply py-4 px-3 bg-transparent;
    box-shadow: none;
  }
  .option-card.active .option-card-content {
    @apply w-full;
  }
}

.option-card-title {
  @apply font-sans font-bold text-sm text-center leading-tight relative z-20;
}

@screen sm {
  .option-card-icon,
.option-card-close,
.option-card-toggled-content {
    transition: 0.3s ease;
  }
}

.option-card-icon {
  @apply w-full mt-auto object-contain object-center;
  height: 75%;
}
.option-card.active .option-card-icon {
  @apply opacity-0;
  transform: translateY(15px);
}
@screen sm {
  .option-card-icon {
    height: 40%;
    margin-bottom: 100%;
  }
}

.option-card-close {
  @apply flex justify-center items-center opacity-0 pointer-events-none absolute z-50 bottom-0 right-0 w-12 h-12 cursor-pointer overflow-hidden;
  text-indent: -9999px;
}
.option-card-close::before {
  @apply absolute z-10 top-0 left-0 rounded-full bg-medium-gray;
  content: " ";
  width: 160%;
  height: 160%;
}
.option-card-close::after {
  @apply absolute z-20 inset-0 bg-no-repeat;
  content: " ";
  background-image: url("../images/close.svg");
  background-position: left 60% top 60%;
}
.option-card.active .option-card-close {
  @apply opacity-100 pointer-events-auto;
}

.option-card-toggled-content {
  @apply block absolute z-30 inset-0 pt-12 pb-4 pl-4 pr-4 flex flex-col justify-center items-center text-left opacity-0 pointer-events-none;
  transform: translateY(15px);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}
.option-card.active .option-card-toggled-content {
  @apply opacity-100 pointer-events-auto;
  transform: translateY(0);
}
@screen sm {
  .option-card-toggled-content {
    @apply z-10 bg-white rounded-lg;
  }
}

[data-duplicate-custom-option],
[data-card-toggle] {
  @apply cursor-pointer;
}
[data-duplicate-custom-option].active,
[data-card-toggle].active {
  @apply cursor-auto;
}

.radio-card {
  @apply flex w-full bg-white rounded-lg justify-center items-center font-bold text-center leading-tight p-2 cursor-pointer;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}
input:checked + .radio-card {
  @apply bg-blue text-white;
}
@screen sm {
  .radio-card {
    @apply h-28 p-6;
    transition: 0.3s ease;
  }
}

.collapsible-item.is-expanded {
  @apply bg-light-blue;
}

.collapsible-item-header {
  @apply flex justify-start items-center w-full text-md text-blue py-4 pr-4;
}
.collapsible-item-header::after {
  @apply absolute opacity-0 pointer-events-none;
  content: url("../images/collapse-icon.svg");
}
.collapsible-item-header::before {
  @apply ml-3 mr-4;
  content: url("../images/expand-icon.svg");
  width: 29px;
  height: 29px;
}
.is-expanded .collapsible-item-header::before {
  content: url("../images/collapse-icon.svg");
}

.collapsible-body {
  @apply px-4;
}

[data-collapsible] {
  overflow: hidden;
  transition: 0.3s ease;
}

.hide-me {
  display: none !important;
}

[data-definition] {
  @apply z-40;
}

.definition-wrapper {
  @apply bg-blue text-white opacity-0;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.25);
  z-index: -9999;
}
.definition-wrapper[data-show] {
  @apply opacity-100 z-50;
}
@screen sm {
  .definition-wrapper {
    @apply bg-white text-gray;
    border-radius: 8px;
    width: 19.25rem;
  }
  .definition-wrapper::after {
    @apply absolute inset-0 bg-white;
    content: " ";
    border-radius: 8px;
    z-index: 0;
  }
}
@screen mobile {
  .definition-wrapper {
    position: fixed !important;
    left: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    top: auto !important;
    transform: none !important;
  }
}

.definition-arrow,
.definition-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;
}

.definition-arrow::before {
  @apply bg-white;
  content: " ";
  box-shadow: -4px 4px 13px 0 rgba(0, 0, 0, 0.125);
  transform: rotate(45deg);
}

[data-popper-placement^=top] .definition-arrow {
  bottom: -4px;
}
[data-popper-placement^=bottom] .definition-arrow {
  top: -4px;
}
[data-popper-placement^=left] .definition-arrow {
  right: -4px;
}
[data-popper-placement^=right] .definition-arrow {
  left: -4px;
}

.definition-term,
.definition-definition {
  @apply block;
}

.definition-close {
  padding-right: 1.25em;
}
.definition-close::after {
  content: "×";
  font-size: 2em;
  position: absolute;
  margin-left: 0.125em;
  margin-top: -0.25em;
}

.input-underlined {
  @apply appearance-none bg-transparent rounded-none border-b-2 border-gray outline-none text-gray max-w-full;
}

@screen mobile {
  .rd-container-attachment {
    position: fixed !important;
    z-index: 30 !important;
    left: 50% !important;
    top: 50% !important;
    transform: translateX(-50%) translateY(-50%) !important;
    box-shadow: 0 0 500px 500px rgba(0, 0, 0, 0.5);
  }
  .rome-hidden .rd-container-attachment {
    display: none !important;
  }
}
@screen sm {
  .rd-container-attachment {
    margin-top: -2px;
  }
}

.rd-day-body {
  padding: 11px;
}

.rd-days {
  margin-top: 12px;
}

.rd-time-selected {
  @apply hidden;
}

.rd-time-list {
  @apply relative;
}

.grecaptcha-badge {
  position: absolute !important;
}

.field:last-child {
  margin-bottom: 0 !important;
}

.result:not(.filled),
.result-section:not(.filled),
.result-group:not(.filled) {
  @apply hidden;
}

.input-radio,
.input-checkbox {
  @apply absolute opacity-0 h-px w-px pointer-events-none;
}

.radio,
.checkbox {
  @apply relative pl-9;
}
.radio::before,
.checkbox::before {
  @apply absolute top-0 left-0 bg-white border-2 border-medium-gray h-6 w-6;
  content: " ";
}
:focus + .radio::before,
:focus + .checkbox::before {
  outline-width: 2px;
  outline-style: solid;
  outline-color: Highlight;
}
@media (-webkit-min-device-pixel-ratio: 0) {
  :focus + .radio::before,
:focus + .checkbox::before {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }
}

.radio::before {
  @apply rounded-full;
}
:checked + .radio::before {
  @apply bg-blue border-blue;
}
:checked + .radio::after {
  @apply absolute top-0 left-0 bg-white h-2 w-2 m-2 rounded-full;
  content: " ";
}

.checkbox::before {
  @apply rounded;
}
:checked + .checkbox::after {
  @apply absolute top-0 border-2 border-transparent h-6 w-6 bg-contain bg-center bg-no-repeat;
  content: " ";
  left: 4px;
  background-image: url("../images/check.svg");
}

.expand-plus {
  @apply inline-block font-bold;
}
.expand-plus::before {
  @apply inline-block h-6 w-6 bg-contain bg-left bg-no-repeat align-top mr-3;
  content: " ";
  background-image: url("../images/plus.svg");
}

.modal {
  @apply hidden;
}
.modal.is-open {
  @apply block;
}

.modal-title {
  @apply text-md text-blue;
}
@screen sm {
  .modal-title {
    font-size: 1.375rem;
  }
}

.modal-overlay {
  @apply fixed z-50 inset-0 flex justify-center items-center;
  background-color: rgba(65, 64, 66, 0.5);
}

.modal-dialog {
  @apply bg-white px-4 py-6 w-full max-w-2xl mx-4 max-h-screen overflow-y-auto;
}
@screen sm {
  .modal-dialog {
    @apply py-16;
  }
}

.modal-header {
  @apply px-5 mb-1;
}
@screen sm {
  .modal-header {
    @apply mb-4;
  }
}

.modal-content {
  @apply px-5 mb-3;
}
@screen sm {
  .modal-content {
    @apply mb-4;
  }
}

.main {
  flex-basis: auto;
}

.section {
  @apply hidden;
}
.section.active {
  @apply block;
}

.section-nav-item {
  @apply relative;
  text-indent: -99999px;
}
.section-nav-item::after {
  @apply bg-white absolute rounded-full z-20;
  content: " ";
  height: 5px;
  width: 5px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.section-nav-item.active::before {
  @apply absolute h-4 rounded-full bg-blue z-10 inset-x-0;
  content: " ";
  top: 50%;
  transform: translateY(-50%);
}

.title {
  font-size: 1.9375rem;
}
@screen sm {
  .title {
    font-size: 2.0625rem;
  }
}

.section-header {
  font-size: 1.0625rem;
}
@screen sm {
  .section-header {
    font-size: 1.375rem;
  }
}

.footer-links {
  font-size: 0.6875rem;
}
.footer-links > a:not(:last-child)::after {
  content: "|";
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.list {
  @apply list-disc pl-4;
}
.list ul {
  @apply list-none pl-4;
}
.list ul li::before {
  @apply absolute;
  content: "-";
  margin-left: -1em;
}

/*! purgecss end ignore */