.collapsible-item {
  &.is-expanded {
    @apply bg-light-blue;
  }
}

.collapsible-item-header {
  @apply flex justify-start items-center w-full text-md text-blue py-4 pr-4;

  // Cache expanded state asset to avoid delay
  &::after {
    @apply absolute opacity-0 pointer-events-none;

    content: url('../images/collapse-icon.svg');
  }

  &::before {
    @apply ml-3 mr-4;

    content: url('../images/expand-icon.svg');
    width: 29px;
    height: 29px;

    .is-expanded & {
      content: url('../images/collapse-icon.svg');
    }
  }
}

.collapsible-body {
  @apply px-4;
}

[data-collapsible] {
  overflow: hidden;
  transition: 0.3s ease;
}

.hide-me {
  display: none !important;
}
