$card-box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);

.option-card-list {
  @apply flex flex-wrap -mx-2;
}

.option-card-inner {
  @apply relative block w-full h-0;

  padding-bottom: 142.45%;

  .option-card-custom & {
    padding-bottom: 71.225%;
  }

  @screen sm {
    padding-bottom: 200%;

    .option-card-custom & {
      padding-bottom: 200%;
    }

    &::before {
      @apply block absolute top-0 left-0 w-full h-0 bg-white rounded-lg;
  
      content: " ";
      padding-bottom: 116.4%;
      transition: 0.3s ease;
  
      .collapsible-item.is-expanded & {
        box-shadow: $card-box-shadow;
      }
  
      .option-card.active & {
        box-shadow: none;
      }
    }
  }
}

.option-card-content {
  @apply absolute inset-y-0 left-0 w-full flex flex-col text-center p-2 bg-white rounded-lg;

  box-shadow: $card-box-shadow;

  .option-card:nth-child(2n) & {
    @apply right-0 left-auto;
  }

  .option-card.active & {
    @apply z-40;

    box-shadow: none;
    width: calc(200% + theme("spacing.4"));
  }

  .option-card-custom.active & {
    width: 100%;
  }

  @screen sm {
    @apply py-4 px-3 bg-transparent;

    box-shadow: none;

    .option-card.active & {
      @apply w-full;
    }
  }
}

.option-card-title {
  @apply font-sans font-bold text-sm text-center leading-tight relative z-20;
}

.option-card-icon,
.option-card-close,
.option-card-toggled-content {
  @screen sm {
    transition: 0.3s ease;
  }
}

.option-card-icon {
  @apply w-full mt-auto object-contain object-center;

  height: 75%;

  .option-card.active & {
    @apply opacity-0;

    transform: translateY(15px);
  }

  @screen sm {
    height: 40%;
    margin-bottom: 100%;
  }
}

.option-card-close {
  @apply flex justify-center items-center opacity-0 pointer-events-none absolute z-50 bottom-0 right-0 w-12 h-12 cursor-pointer overflow-hidden;

  text-indent: -9999px;

  &::before {
    @apply absolute z-10 top-0 left-0 rounded-full bg-medium-gray;

    content: ' ';
    width: 160%;
    height: 160%;
  }

  &::after {
    @apply absolute z-20 inset-0 bg-no-repeat;

    content: ' ';
    background-image: url('../images/close.svg');
    background-position: left 60% top 60%;
  }

  .option-card.active & {
    @apply opacity-100 pointer-events-auto;
  }
}

.option-card-toggled-content {
  @apply block absolute z-30 inset-0 pt-12 pb-4 pl-4 pr-4 flex flex-col justify-center items-center text-left opacity-0 pointer-events-none;

  transform: translateY(15px);
  box-shadow: $card-box-shadow;

  .option-card.active & {
    @apply opacity-100 pointer-events-auto;

    transform: translateY(0);
  }

  @screen sm {
    @apply z-10 bg-white rounded-lg;
  }
}

[data-duplicate-custom-option],
[data-card-toggle] {
  @apply cursor-pointer;

  &.active {
    @apply cursor-auto;
  }
}

.radio-card {
  @apply flex w-full bg-white rounded-lg justify-center items-center font-bold text-center leading-tight p-2 cursor-pointer;

  box-shadow: $card-box-shadow;

  input:checked + & {
    @apply bg-blue text-white;
  }

  @screen sm {
    @apply h-28 p-6;

    transition: 0.3s ease;
  }
}
