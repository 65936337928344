.main {
  flex-basis: auto;
}

.section {
  @apply hidden;

  &.active {
    @apply block;
  }
}

.section-nav-item {
  @apply relative;

  text-indent: -99999px;

  &::after {
    @apply bg-white absolute rounded-full z-20;

    content: ' ';
    height: 5px;
    width: 5px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &.active {
    &::before {
      @apply absolute h-4 rounded-full bg-blue z-10 inset-x-0;

      content: ' ';
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
