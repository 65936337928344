.button {
  @apply inline-block text-blue border border-blue rounded-full font-sans font-bold p-2;

  font-size: 0.8125rem;

  &.button-prev {
    @apply pr-3;
  }
  
  &.button-next {
    @apply pl-3;
  }

  &:hover {
    @apply text-white bg-blue;
  }

  @screen sm {
    @apply px-5;

    &.button-prev {
      @apply pl-3;
    }
    
    &.button-next {
      @apply pr-3;
    }
  
  }

  &[disabled],
  &[disabled]:hover {
    @apply border-light-gray bg-light-gray text-gray cursor-wait;
  }
}

.button-prev::before,
.button-next::after {
  @apply inline-block align-middle;

  content: url('../images/caret-blue.svg');
}

// Cache hover state asset to avoid delay
.button-prev::after,
.button-next::before {
  @apply absolute opacity-0 pointer-events-none;

  content: url('../images/caret-white.svg');
}

.button-prev:hover::before,
.button-next:hover::after {
  content: url('../images/caret-white.svg');
}

.button-prev::before {
  @apply mr-2;

  transform: scaleX(-1);

  @screen sm {
    @apply mr-3;
  }
}

.button-next::after {
  @apply ml-2;

  @screen sm {
    @apply ml-3;
  }
}

.button-blue {
  @apply bg-blue text-white;

  &:hover {
    @apply bg-white text-blue;
  }

  &[disabled],
  &[disabled]:hover {
    @apply border-light-gray bg-light-gray text-gray cursor-wait;
  }

  &.button-prev::before,
  &.button-next::after {
    content: url('../images/caret-white.svg');
  }

  &.button-prev:hover::before,
  &.button-next:hover::after {
    content: url('../images/caret-blue.svg');
  }
}
