.input-underlined {
  @apply appearance-none bg-transparent rounded-none border-b-2 border-gray outline-none text-gray max-w-full;
}

.rd-container-attachment {
  @screen mobile {
    position: fixed !important;
    z-index: 30 !important;
    left: 50% !important;
    top: 50% !important;
    transform: translateX(-50%) translateY(-50%) !important;
    box-shadow: 0 0 500px 500px rgba(0, 0, 0, 0.5);

    .rome-hidden & {
      display: none !important;
    }
  }

  @screen sm {
    margin-top: -2px;
  }
}

.rd-day-body {
  padding: 11px;
}

.rd-days {
  margin-top: 12px;
}

.rd-time-selected {
  @apply hidden;
}

.rd-time-list {
  @apply relative;
}

.grecaptcha-badge {
  position: absolute !important;
}

.field {
  &:last-child {
    margin-bottom: 0 !important;
  }
}

.result,
.result-section,
.result-group {
  &:not(.filled) {
    @apply hidden;
  }
}

.input-radio,
.input-checkbox {
  @apply absolute opacity-0 h-px w-px pointer-events-none;
}

.radio,
.checkbox {
  @apply relative pl-9;

  &::before {
    @apply absolute top-0 left-0 bg-white border-2 border-medium-gray h-6 w-6;

    content: " ";

    :focus + & {
      outline-width: 2px;
      outline-style: solid;
      outline-color: Highlight;

      @media (-webkit-min-device-pixel-ratio:0) {
        outline-color: -webkit-focus-ring-color;
        outline-style: auto;
      }
    }
  }
}

.radio {
  &::before {
    @apply rounded-full;
  }

  :checked + & {
    &::before {
      @apply bg-blue border-blue;
    }

    &::after {
      @apply absolute top-0 left-0 bg-white h-2 w-2 m-2 rounded-full;

      content: " ";
    }
  }
}

.checkbox {
  &::before {
    @apply rounded;
  }

  :checked + & {
    &::after {
      @apply absolute top-0 border-2 border-transparent h-6 w-6 bg-contain bg-center bg-no-repeat;

      content: ' ';
      left: 4px;
      background-image: url('../images/check.svg');
    }
  }
}

.expand-plus {
  @apply inline-block font-bold;

  &::before {
    @apply inline-block h-6 w-6 bg-contain bg-left bg-no-repeat align-top mr-3;

    content: ' ';
    background-image: url('../images/plus.svg')
  }
}
