.modal {
  @apply hidden;

  &.is-open {
    @apply block;
  }
}

.modal-title {
  @apply text-md text-blue;

  @screen sm {
    font-size: 1.375rem;
  }
}

.modal-overlay {
  @apply fixed z-50 inset-0 flex justify-center items-center;

  background-color: rgba(65, 64, 66, 0.5);
}

.modal-dialog {
  @apply bg-white px-4 py-6 w-full max-w-2xl mx-4 max-h-screen overflow-y-auto;

  @screen sm {
    @apply py-16;
  }
}

.modal-header {
  @apply px-5 mb-1;

  @screen sm {
    @apply mb-4;
  }
}

.modal-content {
  @apply px-5 mb-3;

  @screen sm {
    @apply mb-4;
  }
}
